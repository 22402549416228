import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AuthService,
  AuthState,
  FactorsState,
  MFAActions,
  PatientState,
  ResultActions,
  ResultsState,
  dashboardResultsQuery,
  getMFASelectionStatus,
  logOut,
  refreshSession,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import * as authActions from '@patient-ui/patient-web/store';
import * as patientActions from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';
import {
  Patient,
  PortalUser,
  ReportHeaderSummary,
} from '@patient-ui/shared/models';
import { ModalComponent } from '@patient-ui/shared-ui/ui';

import { MfaSetupConfirmationComponent } from '../mfa-setup-confirmation/mfa-setup-confirmation.component';

@Component({
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('emailVerifyModalRef') emailVerifyModal!: ModalComponent;
  @HostBinding('class') cssClass = 'd-flex flex-column vh-100';
  @ViewChild('ieNotificationModalRef', { static: true })
  ieNotificationModalRef!: TemplateRef<unknown>;
  mfaStatus$ = this.mfaStore.select(getMFASelectionStatus);
  destroyed = new Subject();
  patient?: Patient;
  authAttemptsExhausted = false;
  isEligibleToViewResults = false;
  serviceError = false;
  reportHeadersExist = false;
  resultSummary!: ReportHeaderSummary;
  primaryAccountHolder?: Patient;
  isProcessing$ = new BehaviorSubject<boolean>(true);
  currentTab = '';
  tabKey = 'open_tabs';
  openTabs: string[] = [];

  constructor(
    private patientStore: Store<PatientState>,
    private resultStore: Store<ResultsState>,
    private modalService: NgbModal,
    private authstore: Store<AuthState>,
    private mfaStore: Store<FactorsState>,
    private activeModal: NgbModal,
    private authService: AuthService
  ) {
    this.addTab();
  }

  ngOnInit(): void {
    this.resultStore.dispatch(ResultActions.clearResults());
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        this.patient = patientState.primaryAccountHolder;
        this.primaryAccountHolder = patientState.primaryAccountHolder;
        const PASSED = 'passed';
        if (this.patient && this.primaryAccountHolder) {
          if (this.patient.isDependent) {
            this.isEligibleToViewResults = this.patient.isMinor
              ? this.primaryAccountHolder.authenticationQuizStatus === PASSED
              : this.patient.authenticationQuizStatus === PASSED;
          } else {
            this.isEligibleToViewResults =
              this.patient.authenticationQuizStatus === PASSED;
          }

          if (this.isEligibleToViewResults) {
            this.resultStore.dispatch(ResultActions.loadResults());
            this.resultStore
              .select(dashboardResultsQuery.selectResultsPayloadStatus)
              .pipe(takeUntil(this.destroyed))
              .subscribe((state) => {
                switch (state) {
                  case RequestStatus.Success: {
                    this.resultStore
                      .select(dashboardResultsQuery.selectResultSummary)
                      .pipe(takeUntil(this.destroyed))
                      .subscribe((resultState) => {
                        this.reportHeadersExist = resultState.totalAll > 0;
                        if (this.reportHeadersExist) {
                          this.resultSummary = new ReportHeaderSummary(
                            resultState
                          );
                        }
                        if (resultState.hasResult === true) {
                          this.isProcessing$.next(false);
                        }
                      });
                    const resultHistorySelectedUser: PortalUser = {
                      id: this.primaryAccountHolder?.id || 0,
                      displayName: `${this.primaryAccountHolder?.firstName} ${this.primaryAccountHolder?.lastName}`,
                      quizStatus: PASSED,
                    };
                    this.resultStore.dispatch(
                      ResultActions.setResultHistorySelectedUser({
                        selectedUser: resultHistorySelectedUser,
                      })
                    );
                    this.serviceError = false;
                    break;
                  }
                  case RequestStatus.Failure:
                    this.isProcessing$.next(false);
                    this.serviceError = true;
                    break;
                }
              });
          } else {
            this.isProcessing$.next(false);
          }
        }
        this.authAttemptsExhausted =
          this.patient?.authenticationQuizStatus === 'attempts_exhausted';
      });
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        this.patient = patientState.primaryAccountHolder;
      });
    this.msIeVersion();
    this.mfaStatus$.subscribe((data) => {
      if (data === null && this.patient?.emailVerified) {
        this.modalService.open(MfaSetupConfirmationComponent, {
          backdrop: 'static',
          keyboard: false,
        });
      }
    });
    this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnload() {
    if (this.openTabs.length === 1) {
      sessionStorage.clear();
      localStorage.removeItem(this.tabKey);
      await this.authService.logoutAPI().toPromise();
    }
    this.removeTabs();
  }

  ngAfterViewInit(): void {
    if (!this.patient?.emailVerified) {
      this.emailVerifyModal.open();
    }
  }

  emailVerifiedHandler(verified: boolean) {
    if (verified) {
      this.patientStore.dispatch(patientActions.setPatientPAHEmailVerified());
      this.activeModal.dismissAll('verified');
    }
  }

  verifyLogOutHandler() {
    this.activeModal.dismissAll('loggingOut');
    this.authstore.dispatch(authActions.logOut({ redirect: '/landing' }));
    sessionStorage.clear();
    window.history.replaceState(null, '', '/');
  }

  openNotificationModal() {
    return this.modalService.open(this.ieNotificationModalRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  msIeVersion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    // eslint-disable-next-line no-useless-escape
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.openNotificationModal();
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }

  handleSession(logoutDecision: boolean) {
    if (logoutDecision) {
      this.authstore.dispatch(logOut({ redirect: '/landing' }));
    } else {
      this.authstore.dispatch(refreshSession());
    }
  }

  addTab() {
    const openTabs: string[] = this.getTabs();
    this.currentTab = Math.random().toString(36).substr(2, 9);
    openTabs.push(this.currentTab);
    this.openTabs = openTabs;
    this.setTabs(openTabs);
  }

  removeTabs() {
    const opentabs = this.openTabs.filter(
      (id: string) => id !== this.currentTab
    );
    this.setTabs(opentabs);
  }

  getTabs() {
    const tabs = localStorage.getItem(this.tabKey);
    this.openTabs = tabs ? JSON.parse(tabs) : [];
    return this.openTabs;
  }

  setTabs(tabs: string[]) {
    localStorage.setItem(this.tabKey, JSON.stringify(tabs));
  }

  ngOnDestroy(): void {
    this.removeTabs();
  }
}
